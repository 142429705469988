<template>
    <div>
        <div>Name: {{ data.name }}</div>
        <div>ISIN: <span>{{ data.isin }}</span></div>
        <div @click="open_instrument"
             class="si-chart-view-popup-more-btn">
            details
        </div>
        <template v-for="field in fields">
            <div>
                {{field.title}}: {{data[field.field]}}
            </div>
        </template>
    </div>
</template>
<script>
import router from '@/router/'
export default {
    name: 'ChartTooltip',
    props: ['data', 'fields', 'asset'],
    methods: {
        fetch_info: function (){
            return new Promise(resolve => resolve('test'))
        },
        generate_content: function (){
            let content = ''

            content += `<div>Name: ${this.data.name}</div>`
            content += `<div>ISIN: ${this.data.isin}</div>`

            this.fields.forEach(field => {
                if(!['isin', 'name'].includes(field.field))
                    content += `<div>${field.title}: ${this.data[field.field]}</div>`
            })

            return content
        },
        show_info: async function () {
            let info = await this.fetch_info().then(() => this.generate_content())

            this.$msgbox({
                title: 'Info',
                message: info,
                confirmButtonText: 'OK',
                dangerouslyUseHTMLString: true,
            })
        },
        open_instrument: function() {
            let path_name = ''

            switch (this.asset) {
                case 'equity': path_name = 'instruments_equity_isin'; break;
                case 'bond': path_name = 'instruments_bond_isin'; break;
            }

            router.push({
                name: path_name,
                params: {
                    isin: this.data.isin
                }
            })
        }
    }
}
</script>